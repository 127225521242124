import type { GlobalSearchTab } from "~/components/global-search/index";

const DEFAULT_TAB: GlobalSearchTab = "talent";

export const useGlobalSearch = createSharedComposable(() => {
  const selectedTab = ref<GlobalSearchTab>(DEFAULT_TAB);

  const search = ref("");

  const showGlobalSearchModal = ref(false);

  const toggleModal = useToggle(showGlobalSearchModal);

  onKeyStroke(["k", "K"], (e) => {
    if (e.metaKey) {
      e.preventDefault();
      toggleModal();
    }
  });

  const open = function (tab?: GlobalSearchTab) {
    if (tab) selectedTab.value = tab;

    search.value = "";

    showGlobalSearchModal.value = true;
  };

  const close = function () {
    showGlobalSearchModal.value = false;
  };

  return {
    search,
    selectedTab,
    showGlobalSearchModal,
    open,
    close,
  };
});
